import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";

import { Navigation } from "./components/Navigation";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Dates } from "./pages/Dates";
import { Imprint } from "./pages/Imprint";
import { SocialMedia } from "./pages/SocialMedia";
import { Tutorials } from "./pages/Tutorials";
import { Home } from "./pages/Home";
import { KnittingBelt } from "./pages/KnittingBelt";
import { Sources } from "./pages/Sources";

export default function App(props) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/knittingbelt" element={<KnittingBelt />} />
        <Route path="/ueber" element={<About />} />
        <Route path="/quellen" element={<Sources />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/termine" element={<Dates />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/socialmedia" element={<SocialMedia />} />
        <Route path="/tutorials" element={<Tutorials />} />
      </Route>
    )
  );

  return (
    <div id="router">
      <RouterProvider router={router} />
    </div>
  );
}

const Root = () => {
  return (
    <>
      <div id="App" className="bg-sand min-h-screen flex flex-col">
        <div className="relative ">
          <Header />
        </div>
        <header className="sticky top-0 z-10 ">
          <Navigation />
        </header>
        <div className="flex-1 flex flex-col sm:flex-row pb-10">
          <aside className="w-1/5  p-2"></aside>
          <main id="Outlet" className="flex-1 p-2 ">
            <Outlet />
          </main>
          <aside className="w-1/5  p-2"></aside>
        </div>
        <div id="Footer" className="fixed bottom-0 w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};
