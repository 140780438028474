import ScrollToTop from "../helpers/ScrollToTop";

export const Tutorials = () => {
  return (
    <div class="mb-40">
      <ScrollToTop />
      <div class="mt-10 flex flex-col items-start w-3/5 mx-auto border-hidden border-2 border-slate-400">
        <p class="ml-5 mb-5 font-serif text-2xl text-[#36556A]">
          Gelenkschonend stricken?!
        </p>
        <p class="ml-5 mb-5 font-serif text-xl text-[#36556A]">
          Was ist ein Shetland Knitting Belt?
        </p>
        <div
          class="ml-5 relative h-0 max-w-full w-full"
          style={{ "padding-bottom": "56.25%" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/IAdYHorNlaQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>{" "}
        </div>
      </div>

      <div class="mt-10 flex flex-col items-start w-3/5 mx-auto border-hidden border-2 border-slate-400">
        <p class="ml-5 mb-5 font-serif text-2xl text-[#36556A]">
          Kurzdemo: Stricken mit dem Knitting Belt
        </p>
        <p class="ml-5 mb-5 font-serif text-xl text-[#36556A]">
          rechte Maschen
        </p>
        <div
          class="ml-5 relative h-0 max-w-full w-full"
          style={{ "padding-bottom": "56.25%" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/tOkG3CzRT78"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="mt-10 flex flex-col items-start w-3/5 mx-auto border-hidden border-2 border-slate-400">
        <p class="ml-5 mb-5 font-serif text-2xl text-[#36556A]">
          Kurzdemo: Stricken mit dem Knitting Belt
        </p>
        <p class="ml-5 mb-5 font-serif text-xl text-[#36556A]">linke Maschen</p>
        <div
          class="ml-5 relative h-0 max-w-full w-full"
          style={{ "padding-bottom": "56.25%" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/E1ymc9IbVEQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="mt-10 flex flex-col items-start w-3/5 mx-auto border-hidden border-2 border-slate-400">
        <p class="ml-5 mb-5 font-serif text-2xl text-[#36556A]">
          Kurzdemo: Mehrfarbig mit dem Knitting Belt stricken
        </p>
        <div
          class="ml-5 relative h-0 max-w-full w-full"
          style={{ "padding-bottom": "56.25%" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LIxNtAob4Hw?si=DumSk1zziCKd8DMk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
