import knittingbelt2 from "../images/knittingbelt_2.jpg";
import ScrollToTop from "../helpers/ScrollToTop";

export const Home = () => {
  return (
    <div class="pl-10 pr-10 mb-40 ">
      <ScrollToTop/>
      <p class="mt-5 mb-5 font-serif italic text-2xl text-[#36556A]">Schneller, ergonomischer, gleichmäßiger stricken:</p>
      <p class="mt-5 mb-5 font-serif text-4xl text-[#36556A]">Mit dem Shetland Knitting Belt!</p>
      <img src={knittingbelt2} alt="knittingbelt2" />
      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Das Stricken mit dem Knitting Belt ist eine Methode, bei der die rechte
        Nadel von einem “Nadelkissen” aus Leder und Rosshaar fest gehalten wird.
        Sie bietet einige Vorteile, die man durch das bloße Halten von zwei
        Nadeln beim Stricken nicht so gut erreicht. Und zwar:
      </p>

      <p class="mt-5 mb-5 pl-10 font-sans text-[#36556A]">
        <ul className="list-disc">
          <li>Minimiert das Stricken mit dem Knitting Belt die Bewegungen</li>
          <li>Man hat sehr geringe bis keine Drehungen im Handgelenk</li>
          <li>Man muss die rechte Nadel nicht festhalten und verfällt nicht in einen “Death Grip”</li>
          <li>Das Strickbild wird durch die fehlenden Drehungen wahnsinnig gleichmäßig</li>
          <li>Durch die minimierten Bewegungen erhöht sich die Strickgeschwindigkeit</li>
        </ul>
             
      </p>

      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Die wiederkehrenden, kleinen Bewegungen beim Stricken können zu
        Problemen in Sehnen und Muskulatur, besonders des rechten Arms, führen.
        Sehnenscheidentzündung (Karpaltunnelsyndrom / CTS) oder Mausarm
        (RSI-Syndrom) können die Folge sein.
      </p>

      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Da das Stricken aber auch große gesundheitliche Vorteile bringt - man
        bleibt geistig fit und Stress und Depressionen werden reduziert, das
        Selbstbewusstsein wird durch die fertiggestellten Strickobjekte gestärkt
        - könnt ihr mit Hilfe des Knitting Belts den gesundheitlichen Problemen
        vorbeugen oder vielleicht sogar mit dessen Hilfe trotzdem weiter
        stricken.
      </p>

      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Eine Strickerin mit Arthritis aus den USA hat mir geschrieben, dass sie
        durch den Knitting Belt wieder stricken kann, weil sie damit in der Lage
        ist, ihre rechte Hand so zu positionieren, wie es gut für sie ist: Denn
        sie muss ja die Nadel nicht festhalten.
      </p>

      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Besonders, wenn ihr bereits gesundheitliche Probleme beim Stricken habt,
        möchte ich euch diese Methode sehr ans Herz legen. Im Grunde ist es eine
        Investition in eure Strickzukunft.
      </p>

      <p class="mt-5 mb-5 font-sans text-[#36556A]">
        Ich möchte allerdings auch noch sagen: Der Knitting Belt ist kein
        Allheilmittel! Ihr solltet auch überprüfen, was ihr im Alltag sonst noch
        so mit der rechten Hand macht. Bei mir zum Beispiel kommt es hin und
        wieder zu Problemen, wenn ich mal wieder zu aktiv auf dem Handy war. Ich
        scrolle dann viel, und statt zu tippen, wische ich die Wörter, die ich
        schreiben will. Das geht alles aufs Handgelenk. Danach muss ich meist
        erst mal wieder etwas langsamer machen mit der rechten Hand.
      </p>
    </div>
  );
};
