// import { NewsletterSignup } from '../components/NewsletterSignup';
import about from "../images/about.png";
import insta from "../images/icon_insta.png";
import youtube from "../images/icon_yt.png";
import ravelry from "../images/icon_rav.png";
import ScrollToTop from "../helpers/ScrollToTop";

export const Contact = () => {
  return (
    <div class="pl-10 pr-10 pt-10 pb-10 mb-10 text-[#444444]">
      <ScrollToTop/>
      <div class="w-screen h-screen py-4">
        <div class="container">
          <div class="rounded-lg  w-1/2 mx-auto py-3 md:flex md:px-4 ">
            <div class="mx-auto w-5/6 self-center md:mx-4">
              <img
                src={about}
                alt="Nadine"
                class="rounded-full block mx-auto"
              />
            </div>
            <div class="mt-3 mx-auto w-3/4 self-center">
              <p class="text-xl text-gray-800 tracking-wide leading-5 md:leading-6">
                Nadine Buntenbeck
              </p>
              <p class="text-lg text-[#02add2] tracking-wide leading-5 md:leading-6">
                Knit Wisely
              </p>
              <p class="text-sm text-gray-500 leading-5 md:leading-6">
                hallo@knitwisely.de
              </p>
              <p class="text-sm text-gray-500 leading-5 md:leading-6" />
              <p>Folgt mir auf</p>
              <p>
                <a
                  href="https://www.youtube.com/@knitwisely"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="inline-flex items-baseline w-auto h-8">
                    <img class="self-center h-8" src={youtube} alt="youtube" />
                    <span class="h-8 ml-2 pt-1 align-baseline">YouTube:</span>
                    <span class="h-8 ml-2 pt-1 ">@knitwisely</span>
                  </span>
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/knitwisely/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="inline-flex items-baseline w-auto h-8">
                    <img class="self-center h-8" src={insta} alt="instagram" />
                    <span class="h-8 ml-2 pt-1 align-baseline">Instagram:</span>
                    <span class="h-8 ml-2 pt-1 ">knitwisely</span>
                  </span>
                </a>
              </p>
              <p>
                <a
                  href="https://www.ravelry.com/people/knitwizely"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span class="inline-flex items-baseline w-auto h-8">
                    <img class="self-center h-8" src={ravelry} alt="ravelry" />
                    <span class="h-8 ml-2 pt-1 align-baseline">Ravelry:</span>
                    <span class="h-8 ml-2 pt-1 ">@knitwizely</span>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <NewsletterSignup/> */}
    </div>
  );
};
