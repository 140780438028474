import about from "../images/about.png";
import signatur from "../images/signatur_nadine.png"
import ScrollToTop from "../helpers/ScrollToTop";

export const About = () => {
  return (
    <div class="pl-10 pr-10 pb-10 mb-40 text-[#444444]">
      <ScrollToTop/>
      <div class="h-64 flex mt-10">
        <p class="font-serif text-xl mt-auto mb-10 text-[#444444]">
          Herzlich willkommen!
        </p>
        <img src={about} alt="me" class="ml-auto mb-10" />
      </div>
      <div>
        <p class="mb-5">
          Ich bin Nadine. Ich bin knapp 50, Grafik-Designerin und lebe mit Kind,
          Mann und zwei Katzen im schönen Münsterland. Wenn ich nicht gerade
          stricke, fotografiere und male ich sehr gerne, hin und wieder
          modelliere ich sogar. Außerdem backe ich gern mit Sauerteig.
        </p>
        <p class="mb-5">
          Seit 2015 habe ich das Stricken für mich entdeckt. Mit der Zeit merkte
          ich, dass mir die rechte Hand wehtat, wenn ich mit dünneren Nadeln
          strickte (zB 2,5 mm). Ich nehme an, dass sich dabei meine Hand
          verkrampfte, um die dünneren Nadeln festzuhalten - der berüchtigte
          “death grip”! Auch meine Tante, eine langjährige, versierte
          Strickerin, berichtete von gesundheitlichen Problemen durchs Stricken.
          Meine Patentante hatte ebenfalls Probleme und häkelte daher nur noch.
          Ich sah also ein Risiko, ein schönes Hobby anzufangen, und dieses aber
          möglicherweise nicht bis ins hohe Alter ausüben zu können.
        </p>
        <p class="mb-5">
          Da mir das Thema Ergonomie auch im Berufsleben schon immer sehr
          wichtig war, ging ich auf die Suche im Internet - da musste es doch
          eine ergonomischere Art und Weise geben! Ich stieß dabei immer wieder
          auf knitting belts, und sprach so viel davon, dass mein Mann mir
          Weihnachten 2016 kurzerhand einen knitting belt aus Shetland schenkte.
        </p>
        <p class="mb-5">
          Ich fing also an, mit dem knitting belt zu stricken. Anfangs war es
          natürlich gewöhnungsbedürftig, weil ich mich, inspiriert durch die
          Videos über Hazel Tindall, auch umtrainierte und den Faden nun rechts
          statt links führte. Die langen Nadeln waren auch ungewohnt.
        </p>
        <p class="mb-5">
          Ich merkte aber schnell, dass das Stricken mit dem Belt genau mein
          Ding ist. Da man die rechte Nadel nur leicht berührt und nicht selber
          halten muss, fiel der death grip weg. Außerdem hat man nur wenige
          Bewegungen im Handgelenk. Und die Aussicht, irgendwann man richtig
          schnell mit dem Teil zu werden - das ist doch super, mehr Strickstücke
          in kürzerer Zeit fertig kriegen, dachte ich!
        </p>
        <p class="mb-5">
          Ich sehe in meinem Umfeld immer wieder Einige, die Probleme mit dem
          Stricken haben, langsam machen müssen, wenn sie mal wieder zu lange
          gestrickt haben und und und. Und ich bin überzeugt, dass die ganzen
          Shetlanderinnen, die seit dem 18. Jhdt. mit dem belt stricken, nicht
          falsch liegen können. Daher habe ich es mir zum Ziel gesetzt, den
          knitting belt in Deutschland bekannter zu machen. Und vielleicht kann
          diese Methode ja auch euch helfen, gesundheitlichen Problemen
          vorzubeugen oder euch das Stricken weiterhin möglich zu machen. Oder
          euer Stricken beschleunigen. Es würde mich jedenfalls sehr freuen!
        </p>
        <p class="mb-5>">
          Liebe Grüße,<br/>
          Eure
        </p>
        <p >
          <img src={signatur} alt="signatur"/>
        </p>
      </div>
    </div>
  );
};
