import React from 'react';
import { Link } from "react-router-dom";
import logo from "../images/knit_wisely-logo.png"

export class Navigation extends React.Component {
  render() {
    return (
      <>
      <div id="Navigation1" className="h-16 bg-[#36556A] text-[#D5B151] font-serif italic text-4xl flex items-center pl-40" >
        <Link to="/"><img src={logo} alt="logo" className="object-contain h-16"></img></Link>
      </div>
      <div className="grid-cols-3">
        <div/>
          <div id="Navigation2" className="h-10 bg-[#ffffff] text-[#444444] font-serif text-xl flex grid-cols-3 justify-between items-center " >
            <div className="w-1/5 h-full"></div>
            <div className="w-3/5 flex justify-between items-center">
              <Link className="hidden ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/knittingbelt">Knitting Belt</Link>
              <Link className="ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/ueber">Über</Link>
              <Link className="ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/quellen">Quellen</Link>
              <Link className="ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/tutorials">Tutorials</Link>
              <Link className="hidden ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/termine">Termine</Link>
              <Link className="ml-4 mr-4 hover:text-[#02add2] hover:border-b-2 hover:border-t-2 hover:border-[#02add2]" to="/kontakt">Kontakt</Link>
            </div>
            <div className="w-1/5 h-full"></div>
          </div>
        <div/>
      </div>
      </>
    );
  }
}
