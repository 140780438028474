import ScrollToTop from "../helpers/ScrollToTop";

export const Imprint = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <ScrollToTop/>
      <h1 className="text-3xl font-bold mb-4">Impressum</h1>
      <div>
        <h5>Impressum f&uuml;r www.knitwisely.de</h5>
        <p>
          Gem&auml;&szlig; &sect; 28 BDSG widerspreche ich jeder kommerziellen
          Verwendung und Weitergabe meiner Daten.
        </p>
        <h5>
          Inhaltlich Verantwortliche dieser Webseite gem&auml;&szlig; &sect; 6
          TDG ist Nadine Buntenbeck.
        </h5>
        <p>
          Nadine Buntenbeck
          <br />
          Diplom-Designerin (FH)
          <br />
          Sandstra&szlig;e 13c
          <br />
          59387 Ascheberg{" "}
        </p>
        <p>hallo [at] knitwisely.de</p>
        <p>USt-ID-Nr: DE 271990376</p>
        <h5>
          Grafische Gestaltung, Konzeption und Umsetzung des Internetangebots:
        </h5>
        <p>Nadine Buntenbeck</p>
        <h5>
          Alle Inhalte dieser Webseite &copy; Nadine Buntenbeck. Alle Rechte
          vorbehalten.
        </h5>
        <p>
          Inhalt und Bilder dieser Webseite sind urheberrechtlich
          gesch&uuml;tzt. Wenn nicht ausdr&uuml;cklich anders vermerkt, liegen
          die Urheberrechte f&uuml;r s&auml;mtliche Inhalte und Bilder bei
          Nadine Buntenbeck. Der Urheber r&auml;umt Ihnen ganz konkret das
          Nutzungsrecht ein, sich eine private Kopie f&uuml;r pers&ouml;nliche
          Zwecke anzufertigen. Jede weitergehende Verwendung, insbesondere die
          Speicherung in Datenbanken, Ver&ouml;ffentlichung,
          Vervielf&auml;ltigung, Ver&auml;nderung und jede Form von gewerblicher
          Nutzung sowie die Weitergabe an Dritte &#8211; auch in Teilen oder in
          &uuml;berarbeiteter Form &#8211; ohne Zustimmung von Nadine Buntenbeck
          sind untersagt. Unerlaubte Nutzung ist eine Verletzung des
          Urheberrechts und wird strafrechtlich verfolgt.
        </p>
        <h5>Abgrenzung</h5>
        <p>
          Die Web-Pr&auml;senz ist Teil des WWW und dementsprechend mit fremden,
          sich jederzeit wandeln k&ouml;nnenden Webseiten verkn&uuml;pft, die
          folglich auch nicht diesem Verantwortungsbereich unterliegen und
          f&uuml;r die nachfolgende Informationen nicht gelten. Dass die Links
          weder gegen Sitten noch Gesetze versto&szlig;en, wurde genau ein Mal
          gepr&uuml;ft: Bevor sie hier aufgenommen wurden. Links, die zu fremden
          Webseiten f&uuml;hren, erkennen Sie daran, dass sich ein neues
          Browserfenster &ouml;ffnet.{" "}
        </p>
        <p>
          Durch die Anbringung eines Links sind die Inhalte der verbundenen
          Seite gegebenenfalls mit zu verantworten. Dies kann dadurch verhindert
          werden, dass man sich ausdr&uuml;cklich von diesen Inhalten
          distanziert. Hiermit distanziere ich mich ausdr&uuml;cklich von allen
          Inhalten zu gelinkten Seiten auf diesem Internet-Angebot, da diese von
          Fremdanbietern zug&auml;nglich gemacht werden und ich auf deren
          wahrheitsgem&auml;&szlig;en Inhalt keinerlei Einfluss habe.
        </p>
      </div>
    </div>
  );
};
