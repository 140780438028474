import ScrollToTop from "../helpers/ScrollToTop";

export const Sources = () => {
  return (
    <div class="text-[#444444] pl-10 pr-10 pt-10 mb-40">
      <ScrollToTop />
      <p class="mb-5 font-serif italic text-2xl text-[#36556A]">
        Und wo bekomme ich jetzt so etwas her?
      </p>
      <p class="mb-5 font-serif text-4xl text-[#36556A]">Bezugsquellen</p>

      <p class="mb-5 font-sans">
      Ich würde tatsächlich empfehlen, einen Knitting Belt in Schottland zu bestellen. 
      Einfach weil die Produktionskosten in D so hoch sind, dass man trotz Zollgebühren 1:1 dabei herauskommt im Vergleich zur Fertigung in D. 
      Und zum Anderen wissen die Shetlander, wie ein Knitting Belt beschaffen sein muss - 
      dass das Leder nicht zu hart sein darf (weil sich sonst die Nadeln zu schwer hineinstecken lassen) 
      und dass er mit Rosshaar gefüllt sein sollte (andere Materialien werden schneller beschädigt, wenn man jahrelang Stricknadeln aus Edelstahl hineinsteckt).
      </p>

      <p class="mb-5">
      Bei der Anschaffung eines Knitting Belts müsst ihr circa mit 70-80 Euro rechnen.
      Wenn ihr in Shetland bestellt, sind die Belts zwar günstiger (um die 50 Pfund), aber es kommt wahrscheinlich Zoll hinzu 
      (wieviel, weiß ich leider nicht).

      Wenn ihr in Deutschland bei einem Sattler einen in Auftrag gebt, kosten die Produktionskosten, so dass man hier auch bei 70-80 Euro landet.

      Und wenn man selbst bauen möchte und nicht ohnehin Werkzeug zur Lederbearbeitung besitzt, landet man auch bei um die 70 Euro. </p>
      
      <p class="mb-5">
      Das hängt damit zusammen, weil es Rosshaar nur in 1 kg als kleinster Menge, die ich online gefunden habe, gibt. 
      Und 1 kg Rosshaar kostet (Stand August 2024) ungefähr 22 Euro. Benötigen tut man nur ca. 50 Gramm - man könnte sich also mit jemandem zusammentun. 
      Günstiger ist es trotzdem nur marginal, und man hat ja auch noch seine eigene Arbeitszeit. </p>
      
      <p class="mb-5">

      Schlussendlich würde ich empfehlen, in Shetland direkt zu bestellen, denn die Hersteller dort wissen wenigstens, wie ein Knitting Belt beschaffen sein muss.

      Es ist eine Investition, ja, das ist richtig. Aber wenn ihr das Stricken mit dem Knitting Belt beibehaltet, 
      ist es eine gute Prophylaxe, um keine gesundheitlichen Probleme zu bekommen - 
      und für das Garn eines Pullovers gibt man ja durchaus auch mal um die 100 Euro aus (je nach Garnmenge und Faser).
      </p>

      <p class="mb-5 font-sans">
        Wer sich einen Knitting Belt bestellen möchte, kann
        das hier tun (beachtet bitte, dass durch den Brexit Zoll fällig werden kann):
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.shetlandwoolbrokers.co.uk/knitting-belt-c2x35679733"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Jamieson & Smith in Shetland (UK/Shetland)
        </a>
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.jamiesonsofshetland.co.uk/knitting-belt-1236-p.asp"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Jamieson's of Shetland (UK/Shetland)
        </a>
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.lisasleatherworks.co.uk/product-page/traditional-shetland-knitting-belt"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Lisa's Leatherworks (UK/Shetland)
        </a>
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.janettebudge.com/product/shetland-knitting-belt/"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Janette Budge (UK/Shetland)
        </a>
        - auch Nadeln/DPNs
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.etsy.com/de/shop/BobNWeave"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Etsy / BobNWeave (USA/Minnesota)
        </a>
        - auch vegane Belts
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://www.etsy.com/shop/BygoneYarnyStuff"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Etsy / BygoneYarnyStuff (UK/Dorset)
        </a>
      </p>

      <p class="mt-10 mb-5 font-serif text-2xl text-[#36556A]">Nadelspiele</p>
      <p class="mb-5 font-sans">
        Hersteller von längeren Nadelspielen ist addi, aber auch Prym hat welche
        im Sortiment. Bei beiden sind die Nadelspiele aus Aluminium. Leider
        führen jedoch beide nicht die komfortable Länge von 35 cm, sondern beide
        setzen auf die etwas gewöhnungsbedürftigen 40 cm.
      </p>
      <p class="mb-5 font-sans">
        Bei den Anbietern bitte ich um Verständnis, dass ich nicht alle
        Onlineshops aufführen kann, die längere Nadelspiele führen, einfach weil
        man tatsächlich etwas danach suchen muss. Mein Tipp wäre, einfach danach
        zu googeln. Die Suchbegriffe “Hersteller” (zB addi), “Länge” (zB 40 cm),
        “Nadelspiel” oder “Strumpfstricknadel” werden euch sicher Möglichkeiten
        auflisten.
      </p>
      <p class="mb-5 font-sans">
        “Exotischere” Nadeln bestelle ich immer gern bei{" "}
        <a
          href="https://country-creativ.de/Stricknadeln/Nadelspiele/addi-Alu:::448_13_1134.html"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          Country Creativ
        </a>
        , dort gibt es auf jeden Fall die langen addi Nadelspiele.
      </p>

      <p class="mb-5 font-sans">
        Die von den namhaften Herstellern leider nicht angebotenen Längen 25 cm
        und 35 cm kann man in der No-Name-Variante relativ problemlos bei Amazon
        (oder auch AliExpress direkt in Asien bestellen - sofern man eine
        Kreditkarte hat). Die Nadeln sind so günstig, dass auch der Versand
        nicht besonders weh tut. Nur etwas Wartezeit muss man mitbringen. Ich
        empfehle, gleich zwei Sets zu bestellen, falls mal eine Nadel verloren
        geht (alles schon passiert...). Bei den angebotenen Sets sind nur leider
        nicht alle Durchmesser verfügbar, und oft sind nur vier statt fünf
        Nadeln pro Nadelstärke dabei. Und die Spitzen sind definitiv nicht für
        Lace geeignet, leider.
      </p>
      <p class="mb-5 font-sans">
        Bei Amazon findet ihr die No-Name-Nadelspielsets zum Beispiel hier:
        <a
          href="https://www.amazon.de/St%C3%BCck-Stricknadeln-Doppelstricknadeln-Edelstahl-Pullover/dp/B07SV2LH54/ref=sr_1_8?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QDFDU75C3QL0"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Lange Nadelspiele bei Amazon
        </a>
      </p>

      <p class="mb-5 font-sans">
        Bei AliExpress habe ich Nadeln gefunden, indem ich nach dpns oder
        Stricknadeln und 35 cm gesucht habe. Stand heute (Juni 2023) scheint
        aber ein{" "}
        <a
          href="https://de.aliexpress.com/item/4000744800714.html?
        spm=a2g0n.productlist.0.0.17161b24hYu105&browser_id=3a46832b742a46e5920901aeefb878df&aff_trace_key=&aff_platform=msite&m_page_id=001186464a
        37fd-4fd1-8377-bc8a41afbb09"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          “Pr-allison Store”
        </a>{" "}
        der einzige Anbieter zu sein.
      </p>

      <p class="mb-5 font-sans">
        Für Lace-Liebehaberinnen und -Liebhaber noch eine besonderer Tipp: Die
        Hiya Hiya Sharps kann man sich zusammen mit den Hiya Hiya Straight Sets
        zu langen DPNs “zusammenschrauben”. Dann hat man wunderbar spitze
        Nadelspitzen, mit denen man auch ein p2tog-tbl (purl 2 together through
        the back loop) hinkriegt. Ich habe damit mein “Rheinlust” Tuch von
        Melanie Berg gestrickt. Allerdings ist die Lösung etwas teurer, leider,
        und ein ganzes Nadelspiel von vier Nadeln wird man sich davon vermutlich
        nicht leisten wollen.
      </p>

      <p class="mb-5 font-sans">
        Und man sollte auch auf die nicht zum Stricken verwendete Seite eine
        Spitze schrauben, da sonst die Nadel vom Belt nicht so gut gehalten
        wird. Aber das kann ja dann eine Nadel in einer anderen Größe sein, sie
        muss nur in den Knitting Belt passen.
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://country-creativ.de/Stricknadeln/Auswechselbare-Spitzen/HiyaHiya-Sharp:::448_1082_1113.html"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          HiyaHiya Sharp
        </a>
      </p>
      <p class="mb-5 font-sans text-[#36556A]">
        <a
          href="https://country-creativ.de/Stricknadeln/Jackenstricknadeln/HiyaHiya-Straight-Needles:::448_174_370.html"
          class="font-medium text-[#2b7a8b] hover:text-[#02add2] hover:underline visited:text-[#02add2]"
          target="_blank"
          rel="noreferrer"
        >
          HiyaHiya Straight Needles
        </a>
      </p>
    </div>
  );
};
